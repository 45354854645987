import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Episode from '../components/page-inner/episode'
import Layout from '../components/layout'

export const query = graphql`
  query EpisodeTemplateQuery($id: String!, $caseId: String!) {
    episode: sanityEpisode(id: { eq: $id }) {
      id
      startDate
      endDate
      episodeNumber
      fullTitle
      shortEpisodeTitle
      metaTitle
      metaDescription
      path
      episodeSections {
        ... on SanityContentSection {
          _key
          _type
          _rawSectionContent(resolveReferences: { maxDepth: 5 })
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
        ... on SanityEffectivenessSection {
          _key
          _type
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
          coercionApplicable
          coercionContributionScore
          coercionPolicyScore
          constraintApplicable
          constraintContributionScore
          constraintPolicyScore
          _rawCoercionContributionContent
          _rawCoercionPolicyContent
          _rawConstraintContributionContent
          _rawConstraintPolicyContent
          _rawSignalingContributionContent
          _rawSignalingPolicyContent
          signalingApplicable
          signalingContributionScore
          signalingPolicyScore
        }
        ... on SanityPurposesSection {
          _key
          _type
          _rawCoerceContent
          _rawConstrainContent
          _rawSignalContent
          coerceApplicable
          constrainApplicable
          signalApplicable
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
        ... on SanityResolutionsGuideSection {
          _key
          _type
          resolutions {
            _rawProceduralContent
            _rawSubstantiveContent
            _key
            resolutionName
            publishDate
          }
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
        ... on SanitySanctionTypeSection {
          _key
          _type
          _rawContent
          sanctionType {
            id
          }
          sectionSettings {
            collapsibleSection
            sectionIcon {
              iconSource
            }
            sectionTitle
          }
        }
      }
      inCase {
        id
        title
        abbreviations
      }
    }
    relatedEpisodes: allSanityEpisode(
      filter: { inCase: { id: { eq: $caseId } } }
      sort: { fields: episodeNumber }
    ) {
      totalCount
      nodes {
        id
        episodeNumber
        shortEpisodeTitle
        path
        startDate(formatString: "YYYY")
        endDate(formatString: "YYYY")
      }
    }
  }
`

const EpisodeTemplate = props => {
  const { data, errors } = props
  const episodeData = data && data.episode
  const episodes = data && data.relatedEpisodes

  // e.g. Libya I - EP2
  const title = data.episode.fullTitle

  const metaTitle = data.episode.metaTitle || title
  const metaDescription = data.episode.metaDescription

  return (
    <Layout title={title} {...props} showEpisodes>
      {errors && <SEO title="GraphQL Error" />}
      {episodeData && <SEO title={metaTitle} description={metaDescription} />}

      {errors && <GraphQLErrorList errors={errors} />}

      {episodeData && <Episode {...episodeData} episodes={episodes} location={props.location} />}
    </Layout>
  )
}

export default EpisodeTemplate

import React from 'react'
import { format } from 'date-fns'

import { getDateRangeArr } from '../../lib/helpers'

import NonExpansiblePanel from '../../mui-components/NonExpansiblePanel/NonExpansiblePanel'
import SectionsChooser from '../sections-chooser'

const EpisodePageContent = props => {
  const startDateFormated = props.startDate
    ? format(new Date(props.startDate), 'DD-MMM-YYYY')
    : null
  const endDateFormated = props.endDate ? format(new Date(props.endDate), 'DD-MMM-YYYY') : null

  return (
    <>
      {/* Static intro */}
      {props.startDate || props.endDate ? (
        <NonExpansiblePanel highlightContent>
          {'Duration: '}
          {getDateRangeArr(
            <strong key={startDateFormated + 'start'}>{startDateFormated}</strong>,
            <strong key={endDateFormated + 'end'}>{endDateFormated || 'Present'}</strong>
          )}
        </NonExpansiblePanel>
      ) : null}

      {/* Dynamic sections */}
      <SectionsChooser
        sections={props.episodeSections}
        startDate={props.startDate}
        endDate={props.endDate}
        location={props.location}
      />
    </>
  )
}

export default EpisodePageContent
